.App {
   text-align: center;
}

.main-content h1 {
   color: #592e90;
   font-weight: bold;
   text-align: center;
   font-size: 50px;
}

.arm_disclosure a {
   color: #0275d8;
   text-decoration: none;
   font-size: 25px;
}

.step-label {
   color: #49535d;
   font-size: 20px;
   font-weight: 700;
   letter-spacing: 1.5px;
   line-height: 26px;
   text-transform: uppercase;
   padding-top: 8px;
}

.step-form {
   width: 1100px;
   height: 800px;
   position: relative;
}

.form-div {
   position: absolute;
   left: 30%;
   top: 30%;
   display: flex;
}

.footer-link a {
   text-decoration: none;
   color: black;
   margin-left: 0.25rem;
   margin-right: 0.25rem;
}

.footer-logo img {
   height: 60px;
   object-fit: contain;
   margin-left: 0.25rem;
   margin-right: 0.25rem;
   opacity: 0.7;
}

.form-div label {
   color: #592d90;
   font-size: 35px;
   font-weight: bold;
}

.form-div select,
.form-div input {
   font-size: 25px;
   width: 400px;
   height: 40px;
}

.continue-btn {
   width: 350px;
   height: 80px;
   background-color: transparent;
   position: absolute;
   left: 50%;
   top: 60%;
   border: none;
}

.back-btn {
   background-color: transparent;
   position: absolute;
   left: 10%;
   top: 77%;
   border: none;
   color: #592d90;
   font-size: 30px;
   font-weight: bold;
}

.six-div {
   position: relative;
   left: 0;
   right: 0;
   width: fit-content;
   height: auto;
   margin: 0 auto;
   border: 4px solid #a6ce39;
   border-radius: 10px;
   padding: 30px;
}

#six_continue {
   border: none;
   display: block;
   margin: 15px 0 0 38px;
   box-shadow: none;
   color: transparent;
   background: url(https://www.loandepotdigital.com/-/media/b9a1277c09aa477b962bd0ecf1abff5a.ashx) center center
      no-repeat;
   width: 210px;
   height: 50px;
   zoom: 150%;
   float: right;
}

.six-input {
   width: fit-content;
}

.six-input label,
.six-input input {
   width: 300px;
   height: 40px;
   font-size: 25px;
}
